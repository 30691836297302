import { ConfigProvider, notification } from "antd"
import { StyleProvider } from "@ant-design/cssinjs"
import useStore, { StoreProvider } from "components/ui/Context"
import Routes from "components/Router"
import "./utils/i18n"
import "./App.scss"
import { useEffect, useRef, useState } from "react"
import { changeLanguage } from "./utils/i18n"
import { getCookieAuth, getI18nextLng } from "lib/cookie"
import { debounce } from "utils/utils"
import { refreshAuthLogic } from "utils/request"
import { ModalConfirm } from "components/ModalConfirm/ModalConfirm"
import { removeAuthLocal } from "lib/localstorage"
import { removeCookies } from "lib/cookie"

notification.config({
  top: 0,
  duration: 3
})

function Interval(fn, time) {
  let timer = false
  this.start = function () {
    if (!this.isRunning()) timer = setInterval(fn, time)
  }
  this.stop = function () {
    clearInterval(timer)
    timer = false
  }
  this.isRunning = function () {
    return timer !== false
  }
}

function App() {
  const { access_token } = useStore()
  const isUserActiveManual = useRef(false)
  const [isShow, setIsShow] = useState()
  const isStopForceLogout = useRef(false)

  let interactiveEvents = [
    "mousemove",
    "keydown",
    "mousedown",
    "pointerdown",
    "pointerup",
    "touchend"
  ]

  const forceLogoutInterval = new Interval(() => {
    if (!isStopForceLogout.current) handleLogOut()
  }, 60000 * 1)

  const showLogoutModalInterval = new Interval(() => {
    if (!!getCookieAuth()?.refresh_token) {
      isStopForceLogout.current = false
      setIsShow(true)
      forceLogoutInterval.start()
    }
  }, 60000 * 14)

  const intervalActive = new Interval(() => {
    isUserActiveManual.current = false
  }, 5000)

  const checkUserActiveInterval = new Interval(() => {
    if (isUserActiveManual.current) {
      showLogoutModalInterval.stop()
    } else {
      showLogoutModalInterval.start()
    }
  }, 5000)

  const handleUserActivationManual = () => {
    if (isUserActiveManual.current) return
    isUserActiveManual.current = true
    intervalActive.stop()
    intervalActive.start()
  }

  const handleLogOut = () => {
    removeAuthLocal()
    removeCookies()
    sessionStorage.removeItem("tabID")
    window.location.href = "/login"
  }

  const handleRefreshToken = () => {
    isStopForceLogout.current = true
    showLogoutModalInterval.stop()
    setIsShow(false)
    refreshAuthLogic()
  }

  useEffect(() => {
    if (access_token) {
      changeLanguage(getI18nextLng())
    }
    if (!!getCookieAuth()?.refresh_token) {
      checkUserActiveInterval.start()
      interactiveEvents.forEach((event) =>
        window.addEventListener(event, debounce(handleUserActivationManual))
      )
      return () => {
        interactiveEvents.forEach((event) =>
          window.removeEventListener(event, debounce(handleUserActivationManual))
        )
        intervalActive.stop()
        checkUserActiveInterval.stop()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: "#1C3F38",
          borderRadius: 4,
          fontFamily: "Inter"
          // Alias Token
          // colorBgContainer: "#f0f2f5"
        }
      }}
    >
      <StyleProvider hashPriority="high">
        <StoreProvider>
          <Routes />
          <ModalConfirm
            visible={isShow}
            onCancel={() => handleLogOut()}
            onSubmit={() => handleRefreshToken()}
          />
          <div id="modal-root"></div>
        </StoreProvider>
      </StyleProvider>
    </ConfigProvider>
  )
}

export default App

const data = {
  sim_status: {
    0: "Inactive",
    1: "Active"
  },
  sim_connected_status: {
    0: "Disconnect",
    1: "Connecting",
    2: "Connected"
  },
  device_status: {
    0: "InStock",
    1: "Sent",
    2: "Delivered",
    3: "Returning"
  },
  receive_device_status: {
    1: "In_transit",
    2: "Received",
    3: "Returning"
  },
  vehicle_status: {
    0: "InStock",
    1: "Sent",
    2: "Delivered",
    3: "Returning"
  },
  organization_type: {
    1: "Tenant",
    2: "ServiceProvider",
    3: "Hybrid"
  },
  history_action: {
    0: "Create",
    1: "Update",
    2: "Update_status"
  }
}
let list = Object.keys(data)
let obj = {}
for (let i = 0; i < list.length; i++) {
  const keyy = list[i]
  // obj[keyy] =
  const item = data[keyy]
  let newList = Object.keys(item).map((j) => {
    return {
      label: item[j],
      value: j
    }
  })
  obj[keyy] = newList
}
