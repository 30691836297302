import { Modal, Button } from "antd"
import { useTranslation } from "react-i18next"
import { iconWarning } from "lib/icons"

export function ModalConfirm({ visible, content, onCancel, onSubmit, loading }) {
  const { t } = useTranslation()

  return (
    <Modal
      className="modal_confirm modal-warning"
      title={
        <div>
          <div className="flex justify-center">{iconWarning}</div>
          <div className="text-center">Would you like to continue your session?</div>
        </div>
      }
      centered
      width={416}
      open={visible}
      onOk={onSubmit}
      closable={false}
      footer={
        <div className="flex gap-4 justify-center mt-5">
          <Button onClick={() => onCancel()}>{t("I1_3335;30_32137")}</Button>
          <Button
            style={{ backgroundColor: "#FAAD14", boxShadow: "none" }}
            type="primary"
            loading={loading}
            onClick={() => onSubmit()}
          >
            OK
          </Button>
        </div>
      }
    >
      <p className="mb-12 text-center text-gray-500">{content}</p>
    </Modal>
  )
}
