export const DEVICE_STATUS = {
  SENT: 0,
  RECEIVED: 1,
  DELIVERED: 2,
  RETURNING: 3,
  INSTOCK: 4,
  INTRANSIT: 5,
  RETURNED: 6
}

export const DEVICE_STATUS_NAME = [
  "Sent",
  "Received",
  "Delivered",
  "Returning",
  "In-stock",
  "In-transit",
  "Returned"
]

export const SIM_STATUS_NAME = ["Active", "Inactive"]

export const sendDeviceStatus = {
  0: { label: "InStock", color: "" },
  1: { label: "Sent", color: "orange" },
  2: { label: "Delivered", color: "#108ee9" },
  3: { label: "Returning", color: "#f50" }
}

export const reportStatus = {
  // 0: { label: "I1_9595;33_33258;33_33254", color: "" },
  // 1: { label: "I1_9607;33_33258;33_33254", color: "" },
  // 2: { label: "I1_9619;33_33258;33_33254", color: "" }
  0: { label: "Pending", color: "" },
  1: { label: "Approved", color: "" },
  2: { label: "Disapproved", color: "" }
}

export const receiveDeviceStatus = {
  1: { label: "I1_4498;33_33258;33_33254", color: "orange" },
  2: { label: "I1_4511;33_33258;33_33254", color: "#108ee9" },
  3: { label: "I1_4524;33_33258;33_33254", color: "#f50" }
}

export const activityStatus = {
  0: { label: "Ongoing", color: "" },
  1: { label: "Upcoming", color: "orange" },
  2: { label: "Completed", color: "#108ee9" },
  3: { label: "Cancelled", color: "#f50" },
  4: { label: "Pickingup", color: "#10e975" },
  5: { label: "Standby", color: "#e910ba" },
  6: { label: "Waiting", color: "#e95510" },
  7: { label: "Started", color: "#10b3e9" }
}

export const activityType = {
  0: "Ride",
  1: "Meeting",
  2: "Airport",
  3: "Rental",
  4: "Package"
}
